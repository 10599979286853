
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




































































































.p-articles {
    .l-section[data-index="0"] { padding: 0}
}

.p-articles__deco {
    --deco-size: 28vw;

    left: calc(-.5 * var(--grid-start-x));
    bottom: calc(-1.5 * var(--grid-start-x));
    transform: rotate(180deg);

    @media #{md("lg")} {
        --deco-size: 24em;
        bottom: vh(-10);
    }
}

