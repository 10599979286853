
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





























































































































































.o-asset {
    display: block;

    &.-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.-cover {

        .o-asset__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &.-contain {
        width: 100%;
        height: 100%;

        .o-asset__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &.-radius {
        overflow: hidden;
        border-radius: $border-radius-md;
    }
}

.o-asset__img {
    @include img;
}

