
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


















































































.o-rollup {
    display: inline-block;
}

.o-rollup__word {

    &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
    }
}

