
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



























































































.c-card-list {
    margin-top: var(--grid-gutter);

    &__groups {
        display: grid;
        gap: 4.5rem;

        @media #{md("md")} {
            gap: calc(var(--grid-gutter) * 2);
        }
    }

    &__group {
        display: grid;
        gap: var(--grid-gutter-half);

        @media #{md("md")} {
            gap: var(--grid-gutter);
            grid-template-columns: 1fr 3fr;
        }

        &-title {
            --offet-right: calc(1.5rem + var(--grid-gutter));
            padding-right: var(--offet-right);

            @media #{md("md", "max")} {
                position: sticky;
                top: 4.5rem;
                z-index: 1;

                h3 {
                    z-index: 2;
                }

                &:after {
                    content: "";
                    background: $color-light;
                    position: absolute;
                    left: -2px;
                    right: -2px;
                    bottom: -0.5rem;
                    top: -4.5rem;
                    z-index: 1;
                }
            }
            h3 {
                display: inline;

                &:after {
                    position: absolute;
                    display: inline-block;
                    content: attr(data-count);

                    min-width: 1.5rem;
                    padding: 0.301rem;

                    font-size: 0.9rem;
                    font-weight: 300;
                    line-height: 1em;
                    text-align: center;

                    background-color: $color-blue-lighter;
                    border-radius: $border-radius-xs;

                    margin-top: -0.2rem;
                    margin-left: 0.4rem;
                }
            }

            span {
                position: absolute;
                top: 0;
                right: var(--offet-right);
            }
        }
    }

    &__group-items {
        display: grid;
        gap: var(--grid-gutter);

        > * + * {
            padding-top: var(--grid-gutter);
            border-top: 1px solid $color-blue-light;
        }
    }
}
