
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                












































































































.o-input-file {
    color: var(--input-color-text);
}

.o-input-file__input {
    display: none;
}

.o-input-file__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // height: var(--input-height);

    .c-btn {
        flex-grow: 0;
        flex-shrink: 0;
        height: var(--height);
        margin-left: .8em;
    }

    &:hover {

        .c-btn {
            color: var(--color-bg);
            border-radius: 0;
        }

        .c-btn__bg:after {
            transform: translate(0, calc(100% - .15em));
        }
    }

    @media #{md("sm", "max")} {
        flex-wrap: wrap;
    }
}

.o-input-file__text {
    display: flex;
    align-items: center;
    width: 100%;
    height: var(--input-height);
    padding-right: .8em;
    padding-left: .8em;
}

