
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                















































.o-tag {
    --tag-padding-y: 0.35em;
    --tag-padding-x: 0.85em;
    --tag-text-color: inherit;
    --tag-bg-color: transparent;

    display: inline-block;

    &__inner {
        display: inline-block;
        color: var(--tag-text-color);
        background-color: var(--tag-bg-color);
        padding: var(--tag-padding-y) var(--tag-padding-x);
        border-radius: $border-radius-sm;
    }

    &.-blue-light {
        --tag-text-color: #{$color-dark};
        --tag-bg-color: #{$color-blue-light};
    }
}
