
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





































































































.p-jobs {
    .l-hero__col.-content {
        .t-content {
            margin-bottom: vh(5);

            html.is-mobile-safari & {
                margin-bottom: 2.25rem;
            }
        }
    }
}

.p-jobs__deco {
    --deco-size: 30vw;

    left: 1em;
    bottom: calc(-1 * var(--grid-start-x));

    html.is-mobile-safari & {
        bottom: -0.75rem;
    }

    @media #{md("lg")} {
        --deco-size: 26em;
        bottom: vh(-10);
    }
}

.p-jobs__empty {
    max-width: 32rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: vh(10);
    padding-bottom: vh(15);
    text-align: center;

    html.is-mobile-safari & {
        padding-top: 4.5rem;
        padding-bottom: 5rem;
    }
}
