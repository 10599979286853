/*=======================================
=            Global settings            =
=======================================*/

// Colors
$color-dark: #2f2f2f;
$color-light: #ffffff;
$color-blue: #699cc6;
$color-blue-light: #c3d7e7;
$color-blue-lighter: #ecf2f9;
$color-orange: #feb11d;

$color-primary: $color-blue;
$color-secondary: $color-blue-light;
$color-secondary-light: $color-blue-lighter;

$font-color: $color-dark;
$selection-bg: $color-dark;
$selection-color: $color-light;

// Typography
$font-families: (
    "sans": (
        "Webfont CF",
        "Helvetica Neue",
        Arial,
        sans-serif,
    ),
);

$font-size: 16px;
$line-height: 1.5;

// Fontfaces
$font-dir: "/static/fonts/";

$fontfaces: (
    "Webfont CF" "criteria-cf-light" 300 normal,
    "Webfont CF" "criteria-cf-regular" 400 normal,
    "Webfont CF" "criteria-cf-medium" 600 normal,
    "Webfont CF" "integral-cf-regular" 700 normal
);

// Accessibility
$accessible-size: 44px;

$border-primary: 1px solid $color-primary;
$border-secondary: 1px solid $color-secondary;

$border-radius-xs: 0.25em;
$border-radius-sm: 0.625em;
$border-radius-md: 1.25em;
$border-radius-lg: 3.75em;

/*=================================================
=            Media queries breakpoints            =
=================================================*/

$breakpoints: (
    "xxs": 0,
    "xs": 480,
    "sm": 768,
    "md": 992,
    "lg": 1280,
    "xl": 1440,
    "xxl": 1920,
);

/*============================
=            Grid            =
============================*/

$grid-width: 92%;
$grid-max-width: 80em; // 1280px
$grid-columns: 12;
$grid-gutters: (
    "xxs": 1.25rem,
    "xs": 1.25rem,
    "sm": 1.75rem,
    "md": 2rem,
    "lg": 2rem,
    "xl": 2rem,
    "xxl": 2rem,
);

/*===========================================
=            Images and SVG dirs            =
===========================================*/

$img-dir: "/img/layout/";
$svg-dir: "/assets/svg/layout/";

/*=================================
=            Z-indexes            =
=================================*/

$layers: (
    "goku": 9000,
    "modal": 200,
    "dropdown": 100,
    "default": 1,
    "limbo": -999,
);

/*==============================
=            Easing            =
==============================*/

$in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

$in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

$in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$in-out-expo: cubic-bezier(1, 0, 0, 1);

$in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.32, 1.275);
$in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$out-back-t: cubic-bezier(0.535, 1.65, 0.635, 1.005);
