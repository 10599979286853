
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




















































































































































































.o-select {
    --input-border: 1px solid var(--input-border-color);

    .o-input-text {
        margin-top: .5rem;
    }

    &.is-focused {
        z-index: 1;

        &.-open-up {
            .o-select__inner {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
        &.-open-down {
            .o-select__inner {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .o-select__arrow {

            &:before {
                opacity: .5;
                transform: scale(1);
            }

            .o-icon {
                animation: anim-select-icon .4s $in-out-quad forwards;
            }
        }

        .o-select__list {
            z-index: 10;
            display: block;
        }
    }
}

.o-select__inner {
    border: var(--input-border);
    border-radius: var(--input-border-radius);
}

.o-select__box {
    z-index: 0;
    display: flex;
    align-items: center;
    height: var(--input-height);
    padding-left: 1em;
    padding-right: var(--input-height);
    color: var(--input-color-text);
    background-color: var(--input-color-bg);
    border-radius: inherit;
    user-select: none;
    cursor: pointer;
    overflow: hidden;

    &:hover {

        .o-select__arrow:before {
            transform: scale(1);
        }
    }
}

.o-select__placeholder {
    display: block;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media #{md("xs", "max")} {
        max-width: 16em;
    }

}

.o-select__arrow {
    z-index: 0;
    position: absolute;
    top: calc((var(--input-height) - 1.25em)/2);
    right: calc((var(--input-height) - 1.25em)/2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    overflow: hidden;

    &:before {
        @include pseudo-el($bg: $color-primary);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        transform: scale(.8);
        transition: opacity .2s ease-out, transform .8s $out-expo;
    }

    .o-icon {

    }
}

.o-select__list {
    position: absolute;
    right: -1px;
    left: -1px;
    width: auto;
    max-height: 12em;
    padding: 0.25em 0;
    @include reset-list;
    border-right: var(--input-border);
    border-left: var(--input-border);
    background-color: var(--input-option-color-bg);
    overflow-y: auto;

    display: none;

    .-open-up & {
        bottom: 100%;
        border-top: var(--input-border);
        border-top-right-radius: var(--input-border-radius);
        border-top-left-radius: var(--input-border-radius);
    }

    .-open-down & {
        top: 100%;
        border-bottom: var(--input-border);
        border-bottom-right-radius: var(--input-border-radius);
        border-bottom-left-radius: var(--input-border-radius);
    }
}

.o-select__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .5em 1em;
    font-size: 0.9em;
    text-align: left;
    cursor: pointer;
    transition: background-color .2s ease-out;

    &:hover {
        background-color: $color-primary;
    }

    &.is-active {
        color: $color-light;
        background-color: $color-secondary;

        &:hover {
            background-color: $color-secondary;

            .o-icon {
                transform: scale(1.2);
            }
        }
    }

    .o-icon {
        font-size: .5rem;
        transition: transform .2s ease-out;
    }
}

.o-select__cross {
    display: block;
    width: .75em;
    height: .75em;

    &:after,
    &:before {
        @include pseudo-el($height: .1em, $bg: currentColor);
        position: absolute;
        top: 50%;
        left: 0;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }
}


@keyframes anim-select-icon {
    0% {
        transform: translate(0);
    }

    50% {
        transform: translate(0, 100%);
    }

    50.001% {
        transform: translate(0, -100%) rotate(180deg);
    }

    100% {
        transform: translate(0) rotate(180deg);
    }
}

