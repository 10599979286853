
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































.l-section {
    --section-padding: #{vh(7)};

    html.is-mobile-safari & {
        --section-padding: 2rem;
    }

    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
}

.l-section__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &.-primary {
        background-color: $color-primary;

        & + .l-section__inner {
            color: $color-light;
        }
    }
}

.l-section__inner {}

.l-section__header {
    margin-bottom: 2em;
}

