
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





































































































































































































































.m-store__cover {
    margin-bottom: vh(10);

    .o-asset {
        width: calc(100% - 2em);
        margin-left: auto;
    }

    @media #{md("md")} {

        .o-asset {
            width: calc(100% - 3em);
        }
    }
}

.m-store__region {
    position: absolute;
    top: 0;
    left: 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.m-store__content {
    --list-cols-xs: 1;
    --list-cols-sm: 2;
    --list-cols-md: 2;

    @media #{md("sm")} {

        .c-list__item {
            display: flex;
            flex-direction: column;
        }
    }
}

.m-store__name {
    margin-bottom: 2em;
}

.m-store__buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .c-btn {
        display: flex;
        flex-basis: 100%;
        margin-right: 1em;
        margin-bottom: 1em;

        @media #{md("sm")} {
            flex-basis: auto;
            margin-bottom: 0;
        }
    }
}

.m-store__privileges {
    display: inline-block;
    margin-top: 1em;

    @media #{md("sm")} {
        display: block;
        width: 100%;
        padding-right: var(--grid-gutter);
    }
    .o-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25em;
        height: 1.25em;
        background-color: $color-secondary;
        border-radius: 50%;
        margin-bottom: .5em;
    }
}

.m-store__links {
    display: flex;
    align-items: center;

    @media #{md("md", "max")} {
        flex-wrap: wrap;
    }
    > *:not(:first-child) {
        margin-left: 0.5em;
    }
    > *:last-child {
        margin-left: 1em;

        @media #{md("md", "max")} {
            width: 100%;
            order: -1;
            margin-left: 0;
            margin-bottom: 1em;
        }
    }

}

.m-store__social-account {
    --ux-padding: .5em;

    display: flex;
    align-items: center;
    padding: var(--ux-padding);

    &:first-of-type {
        margin-left: calc(var(--ux-padding) * -1);
    }

    // UX
    @media (pointer: coarse) {
        --ux-padding: .9em;
    }

    .o-icon {
        color: $color-primary;
    }

    span {
        margin-left: .5em;

        @media #{md("md")} {
            display: none;
            visibility: hidden;
        }
    }
}

.m-store__logo {

    @media #{md("sm")} {
        margin-top: auto !important;
        margin-bottom: .5em;
    }
}

/*=====  End of Opening hours table  ======*/


