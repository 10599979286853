
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





































































































.o-input-group {

    &.-checkbox {

        .o-input-check__label {
            padding-right: .5em;
            padding-left: .5em;
            transition: all .1s ease-in;
        }

        .o-input-check__input:checked + .o-input-check__label {
            color: $color-secondary;
            background-color: $color-primary;
            border-radius: var(--input-border-radius, 0);
            transition: all .2s ease-out;
        }
    }

    &.-radio {

        .o-input-check__input:checked + .o-input-check__label {

            .o-input-check__radio:after {
                transform: scale(1);
                transition: transform .2s ease-out;
            }
        }
    }
}

.o-input-check {
    color: var(--input-color-text);

    &.is-disabled {
        color: var(--input-disabled-color-text);
        pointer-events: none;
    }
}

.o-input-check__input {
    display: none;
}

.o-input-check__label {
    display: inline-flex;
    align-items: flex-start;
    padding-top: .5em;
    padding-bottom: .5em;
    user-select: none;
    cursor: pointer;
}

.o-input-check__radio {
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    width: 18px;
    height: 18px;
    margin-right: .5em;
    // border: $border-light;
    border-radius: 50%;

    &:after {
        @include pseudo-el($width: 50%, $height: 50%, $bg: $color-primary);
        position: absolute;
        top: calc(50%/2);
        left: calc(50%/2);
        border-radius: 50%;
        transform: scale(0);
        transition: transform .1s ease-in;
    }
}

.o-input-check__text {
    display: block;
}

