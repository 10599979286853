
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                























































































.l-modal {
    z-index: 200;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 300%;
    width: 100%;
    height: auto;
    transition-property: left;
    transition-delay: 0.8s;

    &.is-open {
        left: 0;
        display: block;
        transition-delay: 0s;

        .l-modal__bg {
            opacity: 1;
            // transition: opacity .4s $out-quad;
        }

        .l-modal__close {
            transform: translate(-50%, 0);
            transition: transform 0.8s $out-expo 0.4s;
        }

        .l-modal__inner {
            border-top-left-radius: $border-radius-md;
            border-top-right-radius: $border-radius-md;
            opacity: 1;
            transform: translate(0);
            transition: transform 0.8s $out-expo 0.4s, border-radius 0.8s $out-expo 0.4s, opacity 0.4s ease-out 0.4s;
        }
    }
}

.l-modal__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    background-color: $color-secondary;
    // cursor: pointer;
    // opacity: 0;
    // transition: opacity .3s $out-quad;
    // transition: opacity 0s .8s;
}

.l-modal__close {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    transition: transform 0.3s $out-quad;

    .o-btn-close__inner {
        @include blurred-bg;
    }
}

.l-modal__inner {
    position: absolute;
    top: 6em;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding-top: calc(var(--grid-gutter) * 2);
    padding-bottom: calc(var(--grid-gutter) * 2);
    background-color: $color-light;
    border-top-left-radius: 20vw;
    border-top-right-radius: 20vw;
    overflow-y: auto;
    opacity: 0;
    transform: translate(0, 2em);
    transition: transform 0.3s $in-quad, border-radius 0s 0.3s, opacity 0.2s ease-in 0.1s;
}

.l-modal__cover {
    margin-bottom: vh(10);

    .l-modal__label + .o-asset {
        width: calc(100% - 2em);
        margin-left: auto;
    }

    @media #{md("md")} {
        .l-modal__label + .o-asset {
            width: calc(100% - 3em);
        }
    }
}

.l-modal__label {
    position: absolute;
    top: 0;
    left: 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    @extend .t-caps, .-xs;
}

.l-modal__content {
    @media #{md("md", "max")} {
        grid-template-columns: 1fr;
    }

    @media #{md("sm")} {
        --grid-col-left: 1fr;
        --grid-col-right: 1fr;
    }

    @media #{md("md")} {
        --grid-col-left: 5fr;
        --grid-col-right: 7fr;
    }
}

.l-modal__framed {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: $color-blue-lighter;
    border-radius: $border-radius-sm;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    @extend .t-small;

    span {
        display: inline-block;
        flex-grow: 1;
        padding: 1em;

        &:nth-child(2) {
            &:before {
                @include pseudo-el($height: 1px, $bg: $color-primary);
                position: absolute;
                bottom: 100%;
                left: 0;
            }

            &:after {
                @include pseudo-el($width: 1px, $bg: $color-primary);
                position: absolute;
                top: 0;
                left: -1px;
            }
        }
    }
}
