
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



















































.l-grid-content {
    // --grid-col-left: 1fr;
    // --grid-col-right: 2fr;

    display: grid;
    grid-gap: var(--grid-gutter);

    &.-sticky {

        .l-grid-content__inner {
            position: -webkit-sticky;
            position: sticky;
            top: var(--header-height);
        }
    }

    @media #{md("sm")} {
        grid-template-columns: var(--grid-col-left, 1fr) var(--grid-col-right, 2fr);
    }
}

.l-grid-content__col {

    &.-left {}

    &.-right {}
}

