
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                














































































































































































































































































.p-home {

    .l-hero {
        z-index: 1;

        &.has-award {
            margin-bottom: 4rem;

            .l-hero__col.-content {
                padding-bottom: 4em;
            }

        }
    }
    &__implication--slider {
        @media #{md("sm")} {
            padding-bottom: 0;
        }
    }

    .p-home__award {
        position: absolute;
        left: var(--grid-start-x);
        transform: translateY(50%);

        @media #{md("sm")} {
            bottom: 0;
        }
        @media #{md("md")} {
            left: auto;
        }
    }
}

.p-home__banners {

    .c-card-grid {
        --grid-auto-size: auto-fit;

        .c-slider {
            --slide-width: 18em;

            @media #{md("md")} {
                --slide-width: 17em;
            }
            @media #{md("lg")} {
                --slide-width: 18em;
            }
        }

        @media #{md("sm")} {
            .c-card-grid__grid {
                column-gap: var(--grid-gutter);
            }
        }

        img {
            height: 100%;
        }

    }

    .o-deco {
        --deco-size: 50vw;
        top: -18vw;
        right: 0;
        // transform: rotate(51deg);
        overflow: hidden;
    }

    .o-deco__inner {
        right: calc(-.3 * var(--deco-size));
        transform: rotate(51deg);
    }

    @media #{md("xs", "max")} {
        margin-top: 15vw;

        .o-deco {
        }
    }

    @media #{md("xs")} {

        .o-deco {
            --deco-size: 35vw;
            top: calc(-.4 * var(--deco-size));
        }

        .o-deco__inner {
            right: calc(-.4 * var(--deco-size));
        }
    }
}

.p-home__img {
    width: 100%;

    .o-deco {
        --deco-size: 50vw;
        position: relative;
        margin-left: -10vw;
        margin-bottom: -10vw;
    }

    .o-icon.-logo-gb-icon {
        --svg-width: 25vw;

        position: absolute;
        top: calc(50% - (var(--svg-width)/var(--svg-ratio))/2);
        left: calc(50% - var(--svg-width)/2);
        color: $color-light;
    }

    @media #{md("lg")} {

        .o-deco {
            --deco-size: 32em;
        }

        .o-icon.-logo-gb-icon {
            --svg-width: 18em;
        }
    }
}

.p-home__implications {
    .c-slider__slide {

        .c-btn {
            opacity: 0;
            transform: translate(0, 1em);
            transition: opacity .4s $in-quart, transform .4s $in-quart;
        }

        .c-btn__inner:before {
            transform-origin: 0 50%;
        }

        .o-asset {
            opacity: 0;
            transform: scale(.9, .75);
            transform-origin: 50% 0%;
            // transform-origin: 50% 100%;
            transition: opacity .4s $in-quart, transform .4s $in-quart;
        }

        &.is-selected {

            .c-btn {
                opacity: 1;
                transform: translate(0);
                transition: opacity .7s $out-quart .3s, transform .7s $out-quart .3s;
            }

            .o-asset {
                opacity: 1;
                transform: scale(1);
                transform-origin: 50% 100%;
                // transform-origin: 50% 100%;
                transition: opacity .7s $out-quart .3s, transform .7s $out-quart .3s;
            }
        }
    }

    @media #{md("sm", "max")} {
        margin-top: 2em;

        .c-slider__slide {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;

            .o-asset {
                margin-bottom: 2em;
            }
        }
    }

    @media #{md("sm")} {
        --slide-columns: 1fr 1fr;

        .c-slider__slide {
            display: grid;
            grid-column-gap: var(--grid-gutter);
            grid-template-columns: var(--slide-columns);
        }
    }

    @media #{md("md")} {
        --slide-columns: 1fr 2fr;
    }

    @media #{md("lg")} {
        --slide-columns: 1fr 3fr;
    }
}

.p-home__articles {

    .c-slider__nav:after {
        background-color: $color-primary;
    }
}


/*==================================
=            Statistics            =
==================================*/

.o-stats {
    @include reset-list;

    @media #{md("md", "max")} {
        margin-top: 2em;
    }
}

.o-stats__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    &:before {
        @include pseudo-el($height: 2px, $bg: $color-orange);
        position: absolute;
        top: 0;
        left: 0;
        opacity: .4;
    }
}

.o-stats__label {
}


