
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





































































































































































































































.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 200vw;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    transition: z-index 0s 1s, left 0s 1s;

    &.-firstload {
        background-color: $color-light;

        .o-loader__bg {
            border-bottom-right-radius: 0;
        }
    }

    &.is-loading {
        z-index: 500;
        left: 0;
        transition: none;
    }
}

.o-loader__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-primary;
    transform: scale(1, 0);
    transform-origin: 50% 100%;
    will-change: transform;
}

.o-loader__inner {
    display: grid;
    width: 100%;
    max-width: calc(30em + (var(--logo-count) * 2em)) ; // var(--logo-count)
    padding: var(--grid-gutter);
    grid-template-columns: repeat(var(--logo-count), minmax(0, 1fr));
    grid-gap: 2em 1em;
    color: $color-light;

    @media #{md("sm")} {
        grid-gap: 4em 2em;
    }
}

.o-loader__logo {
    display: flex;
    align-items: flex-end;
    overflow: hidden;

    .o-icon,
    .o-asset {
        transform: translate(0, 75%) rotate3d(0, 0, 1, 25deg);
        transform-origin: 0 100%;
        will-change: transform;
        margin: 1px;
    }

    .o-asset {
        padding: 3px;
    }

    &.-big {

        position: absolute;
        top: calc(50% - (var(--svg-ratio) * var(--svg-cover-width)));
        left: 25%;
        width: 50%;
        color: $color-primary;
    }

    &.-gb {
        grid-column-start: 1;
        grid-column-end: calc( var(--logo-count) + 1);

        .o-icon {
            --color-icon-picto: currentColor;
        }
    }

    .o-asset {
        filter: invert(1);
    }
}

