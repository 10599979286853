
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                















































































































.c-card-job {
    --card-padding: 1.25rem;

    &__inner {
        display: grid;
        grid-template-columns: 8rem auto 4rem;
        gap: var(--card-padding);

        &:hover {
            cursor: pointer;
        }

        @media #{md("md", "max")} {
            grid-template-columns: 8rem auto;
        }
    }

    &__asset {
        aspect-ratio: 4/5;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: var(--card-padding);
        padding-top: 0.4rem;

        .-bottom {
            .o-tag {
                margin-right: 0.4rem;
                margin-bottom: 0.4rem;
            }
        }
    }

    .c-card-job__title {
        margin-top: var(--card-padding);
        margin-bottom: 0.2rem;
    }
    .c-card-job__department {
        opacity: 0.5;
    }

    .c-card-job__button {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media #{md("md", "max")} {
            grid-column: 2/3;
        }

        .c-btn {
            --btn-padding-y: 0.75em;
            --btn-padding-x: 1em;

            @media #{md("md")} {
                --btn-padding-y: 1.25em;
                --btn-padding-x: 1.25em;
            }

            &__label {
                @media #{md("md")} {
                    display: none;
                }
            }
        }
    }

    // Transitions
    .c-card-job__asset {
        transform: translateZ(0);

        .o-asset {
            overflow: hidden;

            .o-asset__img {
                transition: transform 0.5s $out-quad;
            }
        }
    }

    &.is-hover {
        .c-card-job__asset {
            .o-asset__img {
                transform: scale(1.05);
                transition: transform 1.75s $out-quad;
            }
        }
    }
}
