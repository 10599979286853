
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                













































































































































.c-auto-slider {

    .flickity-viewport {
        width: 100vw;
        margin-left: calc(-1 * var(--grid-start-x));
    }

    .flickity-slider {
        display: flex;
        align-items: center;
    }
}

.c-auto-slider__header {
    margin-bottom: 2em;

    &:before {
        @include pseudo-el($height: 1px, $bg: $color-secondary);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.c-auto-slider__title {
    display: inline-block;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    &:after {
        @include pseudo-el($height: 3px, $bg: $color-light);
        position: absolute;
        bottom: -1px;
        left: 0;
    }
}

.c-auto-slider__inner {
    max-width: calc(100vw - (2 * var(--grid-start-x)))
}

.c-auto-slider__slide {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 2em;
}


