
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                














































.c-info {
    --info-padding-y: 1em;
    --info-padding-x: 1em;
    --info-color: inherit;
    --info-bg-color: #{$color-blue-lighter};

    display: inline-block;

}

.c-info__inner {
    display: inline-flex;
    align-items: center;
    padding: var(--info-padding-y) var(--info-padding-x);
    color: var(--info-color);
    background-color: var(--info-bg-color);
    border-radius: $border-radius-sm;
}

.c-info__icon {
    flex-shrink: 0;

    & + .c-info__content {
        margin-left: var(--info-padding-x);
    }
}

