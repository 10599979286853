
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                








































































.o-input-boolean {
    --border: 1px solid var(--input-border-color);

    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.o-input-boolean__label {
    a {
        text-decoration: underline;
    }
    & + .o-input-boolean__inputs {
        margin-left: 1.5em;
    }
}

.o-input-boolean__inputs {
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    color: var(--input-border-color);
    border: var(--border);
    border-radius: var(--input-border-radius);
    overflow: hidden;

    input {
        display: none;

        &:checked + label {
            color: var(--input-checked-color-text);
            background-color: var(--input-checked-color-bg);
        }
    }

    label {
        padding: 0.6em 1.2em;

        &:last-child {
            border-left: var(--border);
        }
    }
}
