
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                











































































































































































































.o-at {
    display: block;

    // &.t-cms {
    //     @include anim-reveal-default;
    // }

    &.-number {

        .o-at__l {
            margin-top: 0;
            padding-top: 0;
        }
    }

    html.reduced-motion & {

        &.t-cms > *,
        .o-at__w {
            transition: none !important;
        }
    }
}

.o-at__l {
    margin-top: -.2em;
    padding-top: .2em;
    overflow: hidden;
}

.o-at__c,
.o-at__w {
    // transform: translate(0, 100%) rotate3d(0, 0, 1, 25deg);
    // opacity: 0;
    transform-origin: 0 100%;
    will-change: transform;
}

