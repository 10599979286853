
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































































































































































































































































































.c-form {
    --input-height: 3.75em;

    --input-border-color: #{$color-secondary};
    --input-border-radius: #{$border-radius-sm};

    --input-color-text: #{$color-dark};
    --input-color-bg: #{$color-light};
    --input-option-color-bg: #{$color-light};

    --input-disabled-color-text: #{$color-light};
    --input-disabled-color-bg: #{$color-primary};

    --input-checked-color-text: #{$color-light};
    --input-checked-color-bg: #{$color-primary};

    margin-top: 2em;
    margin-bottom: 2em;

    &.is-sending {
        cursor: wait;
        pointer-events: none;
        opacity: 0.8;
    }
}

.c-form__error {
    padding: 1em;
    margin-bottom: 1em;
    background: rgba(red, 0.06);
    border-radius: $border-radius-sm;
}

.c-form__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.c-form__heading {
    width: 100%;
    margin-bottom: 0.75em;

    &:not(:first-child) {
        margin-top: 2em;
    }
}

.c-form__el {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    margin-top: 1em;
    margin-bottom: 1em;

    &.has-error {
        --input-border-color: red;
    }

    &.-submit {
        display: flex;
        justify-content: center;
    }

    @media #{md("sm")} {
        &.-half {
            width: calc(50% - var(--grid-gutter));
        }
    }
}

.c-form__consent {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
