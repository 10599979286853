
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                













































.o-opening-hours {}

.o-opening-hours__title {
    margin-bottom: 1rem;
}

.o-opening-hours__table {
    width: 100%;
    max-width: 24em;

    td {

        &:nth-child(1) {
            padding-right: 1em;
        }

        &:nth-child(2) {
            white-space: nowrap;
        }
    }
}

