
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



























































































































































.c-section-list {
    &.has-nav {
        padding-bottom: 4rem;
    }

    h2 {
        z-index: 2;
    }
}

.c-section-list__nav {
    --section-list-nav-height: 4.5em;

    position: -webkit-sticky;
    position: sticky;
    top: calc(#{vh(100)} - var(--section-list-nav-height) - 0.5em);
    left: 0;
    z-index: 5;
    width: 100%;
    margin-bottom: calc(-1 * var(--section-list-nav-height));

    display: flex;
    justify-content: center;

    transition: top ease 0.15s; // Fix iOS glitch

    &.has-scroll {
        top: calc(#{vh(100)} - var(--section-list-nav-height));

        .c-section-list__filters {
            position: static;
        }
    }
}

.c-section-list__filters {
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: var(--section-list-nav-height);
    overflow: auto;

    // Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @include blurred-bg($radius: $border-radius-sm);

    @media #{md("md")} {
        align-items: center;
        padding-right: 1em;
        padding-left: 1em;
    }
}

.c-section-list__filter {
    z-index: 0;
    display: block;
    margin-right: 0.5em;
    margin-left: 0.5em;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &:before {
        @include pseudo-el($bg: $color-dark);
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: $border-radius-sm;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    &:hover {
        opacity: 0.5;
    }

    &.is-active {
        color: $color-light;
        pointer-events: none;

        &:before {
            opacity: 1;
        }
    }

    @media #{md("md", "max")} {
        padding: 1em 1.25em;
    }

    @media #{md("md")} {
        padding: 0.75em;
    }
}
