
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                









































































































































.m-partnership {
    @media #{md("xs")} {
        width: calc(5 / 6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
    }
}

.m-partnership__title {
    margin-bottom: 1.5em;
    text-align: center;
}
