
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






















































































































































.p-stores__deco {
    --deco-size: 25vw;
    --deco-color: #{$color-orange};

    top: 0;
    right: 0;
    overflow: hidden;

    .o-deco__inner {
        top: -2vw;
        right: -2vw;
    }
}

.p-stores__banners {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2em;

    &-group {
        display: flex;
        flex-basis: 5em;
    }

    .o-asset {
        margin-right: 1em;
        margin-bottom: 2em;

        &__img {
            width: 8.5em;
            height: 2.5em;
            object-fit: contain;
            object-position: left center;

            @media #{md("lg")} {
                height: 2em;
            }
        }
    }
}
