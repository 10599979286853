
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                









































































































































































.p-contact {
}

.p-contact__deco {
    --deco-size: 28vw;
    --deco-color: #{$color-orange};

    right: 2em;
    bottom: calc(-1 * var(--grid-start-x));

    @media #{md("lg")} {
        --deco-size: 22em;
        bottom: vh(-10);
    }
}

.p-contact__content {
    --list-cols-xs: 1;
    --list-cols-sm: 2;
    --list-cols-md: 1;
    --list-cols-lg: 2;
    --grid-gutter: 3em;

    .o-opening-hours {
        margin-top: 3em;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: $border-secondary;
    }

    @media #{md("md", "max")} {
        grid-template-columns: 1fr;
    }

    @media #{md("sm")} {
        --grid-col-left: 1fr;
        --grid-col-right: 1fr;
    }

    @media #{md("md")} {
        --grid-col-left: 4fr;
        --grid-col-right: 8fr;
    }
}

.p-contact__links {
    > *:not(:first-child) {
        margin-left: 1em;
    }
}

.p-contact__social {
    display: flex;
    align-items: center;
    color: $color-primary;

    @media #{md("xs", "max")} {
        width: $accessible-size;
        height: $accessible-size;
        justify-content: center;
    }
}

.p-contact__phone {
    @media #{md("xs", "max")} {
        order: -1;
        margin-left: 0 !important;
        margin-right: 1em;
    }
}

.p-contact__form {
    margin-top: vh(10);

    html.is-mobile-safari & {
        margin-top: 4.5rem;
    }

    @media #{md("xs")} {
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
        margin-left: auto;
        margin-right: auto;
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
        margin-right: 0;
        padding-left: var(--grid-gutter-half);
    }

    @media #{md("lg")} {
        margin-right: auto;
        padding-left: 0;
    }
}
