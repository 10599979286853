/*=================================
=            Selection            =
=================================*/


::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*=======================================
=            Global settings            =
=======================================*/

$grid-max-width-with-gutters: calc(#{$grid-max-width} + 2 * 1.75rem);

:root {
    --grid-start-x: calc((100 - #{strip-units($grid-width)}) * .5vw + var(--grid-gutter-half));
    --header-height: 3.75em;

    // Grid gutter
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            --grid-gutter: #{map-get($grid-gutters, $key)};
            --grid-gutter-half: calc(var(--grid-gutter)/2);
        }
    }

    // Grid max width + grid gutters lg + half of 100% - grid width %
    // 80em + 2 * 1.75rem (lg gutter) + 3.2em ((100% - 92%)/2 * 80em)
    @media (min-width: 86.7rem) {
    // @media (min-width: $grid-max-width) {
        --grid-start-x: calc(50vw - #{$grid-max-width/2} + var(--grid-gutter-half));
    }
}

*,
*:after,
*:before {
	position: relative;
	outline: none;
}


html {
	font-size: $font-size;

//    // Media queries
//    @media #{md("xs")} {
//        font-size: $font-size + 2;
//    }
//
//    @media #{md("lg")} {
//    	font-size: $font-size + 3;
//    }
//
//    @media #{md("xl")} {
//    	font-size: $font-size + 4;
//    }
//
//    @media #{md("xxl")} {
//    	font-size: $font-size + 5;
//    }

    // Better font rendering for mac
    //&.is-mac-os {
        @include antialiased;
    //}
}
