
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                









































































































































































































































































.c-card {
    --card-padding: 1em;
    --card-side-width: 2em;
    display: grid;
    grid-template-columns: 1fr var(--card-side-width);

    .c-card__asset {
        transform: translateZ(0);

        .o-asset {
            overflow: hidden;

            .o-asset__img {
                transition: transform 0.5s $out-quad;
            }
        }
    }

    .is-hover {
        .c-card__asset {
            .o-asset__img {
                transform: scale(1.05);
                transition: transform 1.75s $out-quad;
            }
        }
    }

    .c-card__buttons .c-btn {
        width: fit-content;
    }

    &.-black {
        .c-card__asset,
        .c-card__inner {
            background: $color-dark;
        }
        .c-card__asset,
        .c-card__content {
            * {
                color: #fff;
            }
        }
        .c-card__inner {
            border: 0;
        }
    }

    &.-article {
        --card-side-width: 1em;

        .c-card__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .c-card__inner {
            padding-top: 0;
            padding-bottom: calc(var(--card-padding) * 3);
        }

        .c-card__asset {
            margin-top: 0;
            padding-top: calc(3 / 4 * 100%);

            picture {
                border-top-left-radius: calc(#{$border-radius-md} - 1px);
                border-top-right-radius: calc(#{$border-radius-md} - 1px);
                transform: translateZ(0);
            }
        }

        .c-card__asset-count {
            position: absolute;
            bottom: var(--card-padding);
            left: var(--card-padding);
        }

        .c-card__content {
            margin-top: var(--card-padding);
        }
        .c-card__title {
            margin-top: 0.5em;
            margin-bottom: 0.65em;
            text-transform: none;
        }
        .c-card__date {
            @extend .t-caps, .-primary;
            margin-top: 0.5em;
        }
        .c-card__buttons {
            left: auto;
            bottom: 0;
            right: 0;
            z-index: 1;

            .c-btn {
                --btn-padding-x: 1em;
                --btn-padding-y: 1.25em;

                &__inner {
                    border-radius: $border-radius-sm 0 0 0;
                }
            }
        }
    }

    &.-banner {
        --card-side-width: 3em;
        .c-card__inner {
            border: 0;
            padding-top: 0;
            background: $color-dark;
        }
        .c-card__asset {
            margin-top: 0;
            padding-top: calc(3 / 2 * 100%);
            z-index: 0;
            border-radius: $border-radius-md $border-radius-md 0 $border-radius-md;
            overflow: hidden;
            transform: translateZ(0);

            &:before {
                @include pseudo-el(
                    $height: 50%,
                    $bg: linear-gradient(to bottom, rgba($color-dark, 0.3), rgba($color-dark, 0))
                );
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
        .c-card__content {
            position: absolute;
            top: calc(var(--card-padding) * 2);
            left: 0;
            width: 100%;
            padding: var(--card-padding);

            .c-card__logo {
                width: 100%;
                height: 3.25em;
                margin: auto;
                filter: invert(1);

                .o-asset__img {
                    object-fit: contain;
                }
            }
            .c-card__title {
                visibility: hidden;
            }
        }

        .c-card__side {
            padding-left: calc(var(--card-padding) / 2);
        }

        .c-card__side-count {
            margin-top: 0.3em;
            margin-bottom: -0.15em;
        }

        .c-card__side-label {
            html.is-chrome & {
                transform: translateX(0.125em) rotate(180deg);
            }
        }

        .c-card__buttons {
            align-items: center;
        }
    }

    &.-job,
    &.-store {
        .c-card__content {
            padding-bottom: 3em;
        }

        .c-card__asset {
            background-color: $color-secondary;
            border-top-right-radius: $border-radius-lg;
            border-bottom-left-radius: calc(#{$border-radius-md} - 4px);
            overflow: hidden;
        }

        .c-card__side-label {
            html.is-chrome & {
                transform: translateX(0.1em) rotate(180deg);
            }
        }

        .c-card__buttons {
            width: fit-content;
        }
    }

    &.-store {
        .c-card__content {
            height: 100%;
        }
        .c-card__title {
            margin-bottom: 0.2em;
        }

        .c-card__info {
            position: absolute;
            bottom: 0;
            z-index: 1;
            transform: translateY(50%);

            --info-padding-y: 0.5em;
            --info-padding-x: 0.5em;
        }

        .c-card__side-icon {
            --svg-width: 0.75em;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.2em;
            height: 1.2em;
            background-color: $color-orange;
            border-radius: 50%;
        }

        .c-card__side-count {
            margin-top: 0.5em;
            margin-bottom: 0.5em;
        }
    }

    &.-job {
        --card-side-width: 1em;

        .c-card__title {
            margin-top: 0.2em;
            margin-bottom: 1em;
        }

        .c-card__buttons {
            .c-btn,
            .c-btn__inner {
                width: auto;
            }
        }
    }
}

.c-card__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--card-padding) var(--card-padding) 0;
    border: 1px solid $color-primary;
    border-radius: $border-radius-md $border-radius-md 0 $border-radius-md;
    overflow: hidden;
}

.c-card__asset {
    left: calc(-1 * var(--card-padding));
    display: block;
    width: calc(1px + 100% + var(--card-padding) * 2);
    height: 0;
    margin-top: auto;
    padding-top: calc(4 / 3 * 100%);

    .o-asset {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.c-card__side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: var(--card-side-width);
    height: 100%;

    & > a {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
    }
}

.c-card__side-label {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}

.c-card__buttons {
    position: absolute;
    bottom: var(--card-padding);
    right: var(--card-padding);
    left: var(--card-padding);
    width: auto;
    display: flex;
    flex-direction: column;

    .c-btn {
        --btn-padding-x: 0.75em;
        --btn-padding-y: 0.75em;
        //width: 100%;

        &:not(:first-child) {
            margin-top: 0.5em;
        }

        .c-btn__inner {
            justify-content: center;
            width: 100%;
        }
    }
}
