
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



















































































































.c-card-grid {
    --grid-auto-size: auto-fill;
    --grid-min-width: 18em;
    margin-top: 2em;

    &.-no-slider {
        .c-card-grid__grid {
            display: grid;
            grid-gap: 1em var(--grid-gutter-half);
            grid-template-columns: repeat(var(--grid-auto-size), minmax(var(--grid-min-width), 1fr));
            justify-content: center;
            overflow-anchor: none;
        }
    }

    &.-slider {
        .c-slider {
            --slide-width: 18em;
        }

        .c-slider__inner:after {
            content: "flickity";
            display: none;
        }

        .c-card {
            width: 100%;
            height: calc(var(--slide-height) - 1px);
            max-width: 18em;

            &:not(:first-child) {
                margin-left: 1em;
            }
        }
    }

    @media #{md("sm")} {
        &.-no-slider {
            .c-card-grid__grid {
                grid-row-gap: vh(7);

                html.is-mobile-safari & {
                    grid-row-gap: 2rem;
                }
            }
        }
    }
}
