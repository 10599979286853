
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






































































































































.c-accordion {
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: $border-secondary;

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }

        .c-accordion__icon {
            opacity: 1;
            transform: rotate(90deg);

            span {
                transition: transform .2s ease-in;

                &:nth-child(2) {
                    transform: scale(0, 1);
                }
            }
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }
    }
}


.c-accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
    user-select: none;
    cursor: pointer;

    &:hover,
    .is-open & {
    }

    &:hover {
    }

    &:hover,
    .is-open &:hover {
    }

    .is-open & {
    }
}

.c-accordion__icon {
    display: block;
    width: 3.5em;
    height: 3.5em;
    border: $border-primary;
    border-radius: 50%;
    flex-shrink: 0;
    opacity: .6;
    transition: transform .6s $out-expo;

    span {
        --size: 40%;
        position: absolute;
        display: block;
        background-color: $color-primary;
        transition: transform .4s ease-out;

        &:nth-child(1) {
            top: calc(50% - var(--size)/2);
            left: calc(50% - 1px/2);
            width: 1px;
            height: var(--size);
        }

        &:nth-child(2) {
            top: calc(50% - 1px/2);
            left: calc(50% - var(--size)/2);
            width: var(--size);
            height: 1px;
        }
    }


    @media #{md("md", "max")} {
        width: 2.5em;
        height: 2.5em;

        .is-open & {
            width: 2.5em;
            height: 2.5em;
        }
    }
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
}


.c-accordion__content {
    // max-height: 400px;
    padding-bottom: 1em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

