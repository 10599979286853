
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





























.o-btn-close {
    cursor: pointer;

    &:hover {

        .o-btn-close__icon {
            color: $color-primary;
        }
    }
}

.o-btn-close__inner {
    display: inline-flex;
    align-items: center;
    padding: 1em 1.5em;
}

.o-btn-close__icon {
    display: block;
    width: 14px;
    height: 14px;
    transition: transform .4s $out-quad, color .3s ease-out;

    .nav-is-open & {
        transform: rotate(180deg);
    }
}

.o-btn-close__dot {
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    background-color: currentColor;
    border-radius: 50%;
    transition: transform .4s $out-quad;

    &.-tl {
        top: 0;
        left: 0;
    }

    &.-tr {
        top: 0;
        right: 0;
    }

    &.-bl {
        bottom: 0;
        left: 0;
    }

    &.-br {
        right: 0;
        bottom: 0;
    }

    &.-c {
        top: calc(50% - 4px/2);
        left: calc(50% - 4px/2);
    }
}

.o-btn-close__label {
    margin-right: 1em;
}

.o-btn-close__text {
    display: block;
}

