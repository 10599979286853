
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































































































































































































.m-job {
    .m-job__content {
        --list-cols-md: 2;
    }

    .m-job__employment-type {
        .o-tag {
            margin-right: 0.4rem;
            margin-bottom: 0.4rem;
        }
    }

    .m-job__department {
        opacity: 0.5;
    }
}

.m-job__form {
    margin-top: vh(15);

    @media #{md("xs")} {
        width: calc(5 / 6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3 / 4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2 / 3 * 100%);
    }
}
