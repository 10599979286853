
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                

















































.o-deco {
    --deco-size: 10em;

    position: absolute;
    display: block;
    width: var(--deco-size);
    overflow: hidden;

    .o-icon {
        --svg-color: var(--deco-color, #{$color-primary});
        --svg-width: var(--deco-size);
    }

    &.-hockey {

        .o-deco__ball {
            bottom: 0%;
            left: 30%;
        }
    }

    &.-basketball {

        .o-deco__ball {
            top: 0%;
            right: 30%;
        }
    }

    &.-baseball {

        .o-deco__ball {
            --deco-ball-size: calc(var(--deco-size)/8);
            top: 60%;
            left: 0;
        }
    }
}

.o-deco__inner {
    display: block;
    width: var(--deco-size);
}

.o-deco__ball {
    --deco-ball-size: calc(var(--deco-size)/15);
    position: absolute;
    display: block;
    width: var(--deco-ball-size);
    height: var(--deco-ball-size);
    border-radius: 50%;
    background-color: var(--deco-ball-color, #{$color-orange});
}

