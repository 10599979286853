
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                












































































































































































































.p-implications {

    .l-grid-content {
        padding-top: 2em;
        margin-bottom: vh(15);

        html.is-mobile-safari & {
            margin-bottom: 7.75rem;
        }
    }

    .l-hero {
        .c-list-inline {
            margin-top: 2em;
        }
    }

    @media #{md("md")} {
        .l-hero {
            &.-image-right {
                .l-hero__col {
                    &.-content {
                        padding-right: calc(0.5/12 * 100%);

                        .l-hero__intro {
                            padding-right: calc(1.5/12 * 100%);
                        }
                    }
                }
            }
        }
    }

    .c-list__item {
        .o-asset {
            width: 6rem;
            height: 6rem;
            margin-bottom: 1rem;

            .o-asset__img {
                object-position: left;
            }
        }
    }
}

.p-implications__deco {
    --deco-size: 25vw;
    --deco-color: #{$color-orange};

    &:nth-child(1) {
        bottom: -7vw;
        left: 1em;
        transform: rotate(90deg);
    }

    &:nth-child(2) {
        z-index: 1;
        top: 0;
        right: 0;
        overflow: hidden;
        mix-blend-mode: screen;

        .o-deco__inner {
            top: calc(var(--deco-size) * -.5);
            right: calc(var(--deco-size) * -.5);
        }
    }

    @media #{md("md")} {
        --deco-size: 17vw;

        &:nth-child(1) {
            bottom: 1em;
            left: 0em;
        }
    }

    @media #{md("lg")} {
        --deco-size: 16em;
    }
}

.p-implications__heading {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: $color-orange;
}


.p-implications__accordion {

    &.is-open {

        .c-accordion__container {
            transform: translate(0, -1em);
            transition: transform .8s $out-expo;
        }
    }

    .c-accordion__header {
        display: grid;
        grid-template-columns: 4em 1fr 2.5em;
        grid-gap: var(--grid-gutter);

        @media #{md("sm")} {
            grid-template-columns: 3.5em 1fr 2.5em;
        }
        @media #{md("md")} {
            grid-template-columns: 5em 1fr 3.5em;
        }
    }

    .c-accordion__logo {
        height: 5.5em;

        .o-asset__img {
            object-position: 0 50%;
        }
    }

    .c-accordion__title {
        grid-column-start: 2;
    }

    .c-accordion__container {
        transition: transform .4s $out-expo;
    }

    .c-accordion__content {

        padding-left: 0;
        padding-right: var(--grid-gutter-half);

        @media #{md("sm")} {
            padding-right: 2.5em;
            padding-left: calc( 3.5em + var(--grid-gutter));
        }

        @media #{md("md")} {
            padding-right: 3.5em;
            padding-left: calc(5em + var(--grid-gutter));
        }
    }
}

.p-implications__partners-list {
    .c-list__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > a, & > span {
            display: block;
        }

        & > span {
            margin-top: 3rem;
        }
        a {
            transition: color .4s ease;
            &:hover {
                color: $color-primary;
            }
        }
    }
}

