
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                







































































.c-push {
    margin-top: vh(10);
    padding: vh(15) var(--grid-gutter);
    text-align: center;
    background-color: $color-primary;
    border-radius: $border-radius-md;
    overflow: hidden;

    html.is-mobile-safari & {
        margin-top: 4.5rem;
        padding: 7.75rem var(--grid-gutter);
    }

    .o-asset {
        overflow: hidden;

        .o-asset__img {
            transition: transform .75s $in-quad;
        }
    }


    &.is-hover .o-asset__img {
        transform: scale(1.05);
        transition: transform 2s $out-quad;
    }
}

.c-push__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-push__title {
    color: $color-light;

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2/3 * 100%);
    }

    @media #{md("lg")} {
        width: calc(1/2 * 100%);
    }
}

.c-push__btn {
    margin-top: 2em;
}

