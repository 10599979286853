
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




















































.c-award {
    display: block;

    @media #{md("xs", "max")} {
        width: calc(100% - var(--grid-start-x) * 3);
    }

    &.-boxed {
        --award-padding-y: 1.25em;
        --award-padding-x: 1.5em;
        --award-color: #{$color-light};
        --award-bg-color: #{$color-dark};

        .c-award__logo {

            & + .c-award__content {
                margin-left: 2em;
                padding-right: 0;
            }
        }
    }
}

.c-award__inner {
    display: inline-flex;
    align-items: center;
    padding: var(--award-padding-y, 0) var(--award-padding-x, 0);
    color: var(--award-color, inherit);
    background-color: var(--award-bg-color, none);
    border-radius: $border-radius-sm;
}

.c-award__logo {
    flex-shrink: 0;

    & + .c-award__content {
        margin-left: 1.25em;
        padding-right: 2em;
    }
}

.c-award__content {
    font-weight: 100;
}

