
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
































































































.m-job {}

.m-job__details {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: $color-secondary;
    border-radius: $border-radius-sm;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;

    span {
        display: inline-block;
        flex-grow: 1;
        padding: 1em;

        &:nth-child(2) {

            &:before {
                @include pseudo-el($height: 1px, $bg: $color-primary);
                position: absolute;
                bottom: 100%;
                left: 0;
            }

            &:after {
                @include pseudo-el($width: 1px, $bg: $color-primary);
                position: absolute;
                top: 0;
                left: -1px;
            }
        }
    }
}

.m-job__content {
    --list-cols-md: 2;

    .t-t2 {
        text-transform: unset;
        font-weight: 600;
    }
}

.m-job__form {
    margin-top: vh(15);

    @media #{md("xs")} {
        width: calc(5/6 * 100%);
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("sm")} {
        width: calc(3/4 * 100%);
    }

    @media #{md("md")} {
        width: calc(2/3 * 100%);
    }
}

