/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    color: $font-color;
    font-family: ff("sans");
    line-height: $line-height;
    min-height: -webkit-fill-available;
}

/*==============================
=            Titles            =
==============================*/

.t-t2,
.t-cms h1,
.t-t3,
.t-cms h2,
.t-t4,
.t-cms h3,
.t-t5,
.t-cms h4 {
    line-height: 1;
    text-transform: uppercase;
}

.t-t1 {
    @include responsive-type(45, 100, 480, 1600);
    font-weight: 700;
    line-height: 0.9;
    text-transform: uppercase;
}

.t-t2,
.t-cms h1 {
    @include responsive-type(30, 64, 480, 1600);
    font-weight: 700;
}

.t-t3,
.t-cms h2 {
    @include responsive-type(24, 45, 480, 1600);
    font-weight: 700;
}

.t-t4,
.t-cms h3 {
    @include responsive-type(18, 35, 480, 1600);
    font-weight: 700;
}

.t-t5,
.t-t5-bold,
.t-cms h4 {
    font-size: 1em;
    line-height: 1.15em;
    font-weight: 300;

    @media #{md("sm")} {
        font-size: 1.25rem;
    }
}

.t-t5-bold {
    font-weight: 700;
}

.t-huge {
    @include responsive-type(100, 190, 480, 1900);
    line-height: 1;
    font-weight: 700;
}

.t-caps {
    font-size: 0.875em;
    line-height: 1.15em;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    &.-md {
        font-size: 1.25em;
    }

    &.-xs {
        font-size: 0.75em;
    }

    &.-primary {
        color: $color-primary;
    }
}

.t-content {
    > *:nth-child(n + 2) {
        margin-top: 1rem;
    }
}

/*==========================================
=            CMS content styles            =
==========================================*/

.t-cms {
    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
        word-spacing: 0.15em;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 1.414em 0 0.5em;
    }

    a {
        cursor: pointer;
        text-overflow: ellipsis;
        max-width: calc(100vw - (var(--grid-gutter) * 2));
        overflow: hidden;
        white-space: nowrap;

        &:not(:only-child) {
            color: $color-primary;
            text-decoration: underline;
            transition: color 0.2s ease-out;

            &:hover {
                color: $color-secondary;
            }
        }

        &:only-child {
            display: inline-block;
            padding-top: 0.5em;
            padding-bottom: 0.5em;

            &:before {
                @include pseudo-el;
                z-index: -1;
                position: absolute;
                bottom: 0;
                left: 0;
                top: auto;
                height: 2px;
                background-color: $color-primary;
                border-radius: inherit;
                transition: transform 0.8s $out-expo;
            }

            &:after {
                @include pseudo-el($height: 2px, $bg: $color-secondary);
                position: absolute;
                bottom: 0;
                left: 0;
                transform: scale(0, 1);
                transition: transform 0.8s $out-expo;
            }

            &:hover {
                &:after,
                &:before {
                    transform: scale(1.05, 1);
                }
            }
        }
    }

    ul,
    ol {
        @include reset-list;

        li {
            padding-left: 2em;
            margin-bottom: 0.5em;

            &:before {
                position: absolute;
            }
        }
    }

    ol {
        counter-reset: ol;

        li {
            counter-increment: ol;

            &:before {
                content: counter(ol) ".";
                top: 0;
                right: calc(100% - 2em);
                width: 2em;
                padding-right: 0.5em;
                text-align: right;
            }
        }
    }

    ul {
        li {
            &:before {
                content: "";
                top: 0.4em;
                left: 0;
                width: 1.2em;
                height: calc(1.2em * 10 / 18);
                background-image: url("~@/assets/svg/arrow-right.svg");
                background-size: contain;
            }
        }
    }

    blockquote {
        padding: 1em 1.25em;
        background-color: $color-secondary-light;
        border-radius: $border-radius-sm;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
        border-radius: $border-radius-sm;
    }

    b,
    strong {
        font-weight: 600;
    }
}

/*=====================================
=            Miscellaneous            =
=====================================*/

sup,
small,
.t-small {
    font-size: 0.875em;
}

sup {
    vertical-align: super;
}

a {
    color: inherit;
    text-decoration: none;
}

address {
    font-style: normal;
}
