
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                

















































































































































































































































.p-about {
    .l-hero {
        .c-list-inline {
            margin-top: 2em;

            .c-btn {
                --btn-border-color: #fff;
            }
        }
    }

    // [class*=-arrow-].o-icon svg {
    //     transform: translateY(-1px)
    // }
}

/*=============================
=            About            =
=============================*/

.o-about {
    display: grid;
    grid-gap: 4em 2em;
    margin-bottom: 2em;

    @media #{md("sm")} {
        grid-template-columns: repeat(2, (minmax(0, 1fr)));
    }

    @media #{md("md")} {
        grid-gap: 6em calc(1 / 12 * 100%);
    }
}

.o-about__slider {
    display: flex;
    flex-direction: column;

    .c-slider {
        @media #{md("sm")} {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .o-about__slide {
            opacity: 0;
            transition: opacity ease 0.2s;
            transition-delay: 0s;

            &.-visible {
                opacity: 1;
                transition: opacity ease 0.2s;
                transition-delay: 0.2s;
            }
        }
    }
}

.o-about__list {
    --list-cols: 1;

    .c-list__item {
        padding-top: 2em;

        ul {
            @include reset-list;
            padding-top: 1em;

            li {
                margin-top: 0.75em;
            }
        }
    }
}

.o-about__fig {
    @media #{md("sm")} {
        right: calc(-1 * var(--grid-start-x));

        .o-asset {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.o-about__gallery {
    //     --slide-width: auto;

    .c-auto-slider__slide {
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        border-bottom-left-radius: $border-radius-md;
        overflow: hidden;

        //         &:not(:first-child) {
        //             margin-left: 2em;
        //         }

        &:nth-child(3n) {
            .o-asset__img {
                height: vh(50);

                html.is-mobile-safari & {
                    height: 30rem;
                }
            }
        }
    }

    .o-asset__img {
        width: auto;
        height: vh(40);

        html.is-mobile-safari & {
            height: 20rem;
        }
    }

    @media #{md("sm")} {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.o-about__gallery-content {
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;

    > *:not(:first-child) {
        margin-top: 0.3rem;
    }

    &:before {
        @include pseudo-el();
        position: absolute;
        top: 0;
        left: 0;
        @include linear-gradient(to top, $color-dark, rgba($color-dark, 0));
        opacity: 0.6;
    }
}

/*================================
=            Director            =
================================*/

.o-director {
    display: grid;
    grid-gap: 1em var(--grid-gutter);
    margin-bottom: var(--grid-gutter);

    @media #{md("md")} {
        grid-template-areas:
            "photo   photo   photo"
            "heading content content";
        grid-template-columns: 1fr 2fr;
        grid-row-gap: 2em;
        margin-bottom: calc(2 * var(--grid-gutter));
    }
}

.o-director__photo {
    @media #{md("md")} {
        grid-area: photo;
    }
}

.o-director__heading {
    @media #{md("md")} {
        grid-area: heading;
    }
}

.o-director__social {
    .o-icon {
        margin-right: 0.5em;
    }
}

.o-director__content {
    @media #{md("md")} {
        grid-area: content;
    }
}
