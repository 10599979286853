
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                























































.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }

    &.-cover {
        width: var(--svg-cover-width, 100%);
        height: auto;
        padding-top: calc(var(--svg-cover-width, 100%) * var(--svg-ratio, 1));

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}


/*----------  SVG Sizes  ----------*/

// Socials
.o-icon.-facebook,
.o-icon.-instagram,
.o-icon.-linkedin {
   --svg-width: 1.75em;
   --svg-ratio: 24/24;
}

// Icons
.o-icon.-info {
   --svg-width: 1.25em;
   --svg-ratio: 23/23;
}

// Arrows
.o-icon.-arrow-left,
.o-icon.-arrow-right {
   --svg-width: 1.2em;
   --svg-ratio: 10/18;
}

.o-icon.-arrow-up,
.o-icon.-arrow-down {
   --svg-width: .35em;
   --svg-ratio: 18/10;
}

// Logos
.o-icon.-logo-gb {
   --svg-width: 8.9em;
   --svg-ratio: 82/433;

   --color-icon-picto: #{$color-primary};
}

.o-icon.-logo-gb-icon {
   --svg-width: 1em;
   --svg-ratio: 76/107;
}

.o-icon.-logo-gb-long {
   --svg-width: 17em;
   --svg-ratio: 32/294;

   --color-icon-picto: #{$color-primary};
}

.o-icon.-logo-smg {
   --svg-width: 7em;
   --svg-ratio: 31/95;

   --color-icon-picto: #{$color-primary};
}

.o-icon.-history {
    --svg-width: 2em;
    --svg-ratio: 46/33;
}

.o-icon.-award {
    --svg-width: 2.4em;
    --svg-ratio: 38/33;
}

// Fields
[class^="o-icon -field-"] svg {
    fill: none;
    stroke: var(--svg-color, currentColor);
    stroke-width: 1px;
    stroke-alignment: inside;
}


.o-icon.-field-tracks {
    --svg-width: 100%;
    --svg-ratio: 396/280;
}

.o-icon.-field-baseball {
    --svg-width: 100%;
    --svg-ratio: 313/322;
}

.o-icon.-field-basketball {
    --svg-width: 100%;
    --svg-ratio: 302/482;
}

.o-icon.-field-football {
    --svg-width: 100%;
    --svg-ratio: 214/318;
}

.o-icon.-field-hockey {
    --svg-width: 100%;
    --svg-ratio: 364/360;
}

.o-icon.-field-mountain {
    --svg-width: 100%;
    --svg-ratio: 342/503;
}

// .o-icon.-logo-a {
//    --svg-width: 1em;
//    --svg-ratio: 39.5/107;
// }

// .o-icon.-logo-eh {
//    --svg-width: 1em;
//    --svg-ratio: 49/170;
// }

// .o-icon.-logo-se {
//    --svg-width: 1em;
//    --svg-ratio: 46/149;
// }

