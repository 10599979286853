
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































































































































































































































































.c-slider {
    --slide-width: 100%;

    .flickity-viewport {
        transition: height 0.2s;
    }

    &.-no-translate {
        --slide-width: 100%;

        .flickity-slider {
            transform: none !important;
        }

        .c-slider__slide {

            &:not(:first-child) {
                top: 0;
                left: 0 !important;
                width: 100%;
                // height: 100%;
            }

            &.is-selected {
                z-index: 1;
            }
        }
    }

    &.-fullwidth {

        .flickity-viewport {
            width: 100vw;
            left: calc(50% - 50vw);
        }

        .flickity-slider {
            // margin-left: var(--grid-start-x);
            // width: calc(100% + 2 * var(--grid-start-x));
            // width: $grid-width;
            // max-width: $grid-max-width;
        }

        .c-slider__slide {

            &:first-child {
                // padding-left: var(--grid-start-x);
            }
        }

        .c-slider__spacer {
            width: var(--grid-start-x);
        }
    }

    &.-fullheight {

        .flickity-viewport {
            height: var(--slide-height) !important;
        }

        .c-slider__slide {
            height: var(--slide-height);
        }
    }

    @media #{md("md")} {
        &.-vertical {
            display: grid !important;
            grid-template-columns: 1fr 2.75rem !important;

            .c-slider__nav {
                margin-top: 0;
                flex-direction: column;
                justify-content: flex-start;

                .c-slider__btn {
                    --slider-btn-width: 2.75rem;
                    transform: rotate(90deg);
                    transform-origin: center;
                    margin-bottom: 0.5em;
                }
            }
        }
    }

    &.is-dragging {

        a,
        button {
            pointer-events: none;
        }
    }

    @media #{md("sm")} {
        --slide-width: calc(1/3 * 100%);

        &.-tabs {
            display: flex;
            flex-direction: column-reverse;

            .c-slider__nav {
                margin-bottom: 2em;

                &:before {
                    top: auto;
                    bottom: 0;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    @media #{md("md")} {
        --slide-width: calc(1/4 * 100%);
    }

    @media #{md("xl")} {
        --slide-width: calc(1/5 * 100%);
    }
}

.c-slider__slide {
    display: block;
    width: var(--slide-width);
}

.c-slider__nav {
    --slider-progress: 0;

    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
    overflow: hidden;

    &:after,
    &:before {
        position: absolute;
        left: 0;
    }

    &:before {
        @include pseudo-el($width: calc(100% - 3.5em * 2 - 3em), $height: 1px, $bg: $color-secondary);
        top: calc(50% - 1px/2);
    }

    &:after {
        @include pseudo-el($width: calc(100% - 3.5em * 2 - 3em), $height:3px, $bg: $color-light);
        top: calc(50% - 3px/2);
        transform: scale(var(--slider-progress), 1);
        transform-origin: 0 50%;
        transition: transform 1s $in-out-quint;
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.c-slider__tabs {
    display: flex;
    margin-right: auto;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-slider__tab {
    display: block;
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 3px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(1, 0);
        transform-origin: 50% 100%;
        transition: transform .2s $out-quad;
    }

    &.is-active {

        &:after {
            transform: scale(1);
        }
    }

    &:not(:first-child) {
        margin-left: 2em;
    }

    @media #{md("md", "max")} {
        font-size: .9em;
    }
}

.c-slider__btn {
    --slider-btn-width: 3.75rem;
    display: block;
    width: var(--slider-btn-width);
    height: var(--slider-btn-width);
    flex-grow: 0;
    flex-shrink: 0;
    // font-size: 2em;
    cursor: pointer;

    .o-icon {
        display: flex;
        place-content: center;
    }

    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid currentColor;
        border-radius: 50%;
        transform: scale(.9);
        transition: transform .2s ease-out;
    }

    &:hover:before {
        transform: scale(1);
        transition: transform .3s ease-out;
    }

    &.is-disabled {
        opacity: .5;
        pointer-events: none;
    }

    &.-prev {
        margin-right: 1.5em;
        transform-origin: 0 50%;
    }

    &.-next {
        transform-origin: 100% 50%;
    }
}

